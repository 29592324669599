import { format, /* isBefore, isEqual, add, subDays,*/ parseJSON } from "date-fns";
import { getServerTime } from "helpers/time";
import { checkBadKey, checkExpToken, checkKey } from "../auth";
import config from "../config";
// import axios from "../axios";
// import { formatMoney } from "./extras";
import {
	drawReceipt,
	getExchangeRate,
	getPaymentPlans,
	getPolicy,
	getPolicyPaymentPlan,
	getPolicyProfExt,
	getPolicyRisks,
	getStreetTypes,
	getParishTowns,
	getGeneralAreas,
	getTransactionSchedule,
	getPolicyTransactions,
	getPolicyProfRatingCodes,
	getPolicyByParams,
	updatePolicyNarratives,
	notingRegistrationNumber,
	genPolicyModification,
	setToCancel,
	cancelPolicy,
} from "api/dNet/Policies";
import { globalNamePolicies } from "api/dNet/GlobalName";
import { renewalPremiumCalculator, submitNote, updateRenewPolicy } from "api/dNet/Renewal";
import { registrySubmit } from "api/dNet/Registry";
import { createCertificate, getCertificate, getCoverNote } from "api/dNet/Certificate";
import { nodeApi } from "api/Middleware";
import { AuthNetworkLayer } from "helpers";
import { BranchData } from "components/Contact";
import { isNotEmpty } from "helpers/misc";
import { getClaimsHandler } from "api/dNet/Claims";
import { removePaymentReminder } from "api/dNet/PaymentReminder";

export default class PolicyNetworkLayer {
	/**
	 * Get More granular detail about a specific policy
	 * @param {Number} policy_id -- the policy id we are fetching for
	 
	 * @param {boolean} isRenewal
	 */
	static async getPolicy(policy_id, isRenewal) {
		let policy = await getPolicy(policy_id, isRenewal);

		return policy;
	}

	/**
	 * Get the policy using the policy number and  license number
	 * @param {String} policy_number
	 * @param {String} regNo
	 */
	static async getPolicyForUW(policy_number, regNo) {
		let response = {};
		response = await getPolicyByParams({ policy_number, regNo });

		return response;
	}

	/**
	 * Get all the Policies associated with an individual
	 * @param {String} global_name_id
	 */
	static async getPolicies(global_name_id) {
		let response = {};
		response = await globalNamePolicies(global_name_id);

		return response;
	}

	/**
	 * Gets policies for re-engagement flow
	 * @param {String} globalNameId
	 */
	static async getReEngagementPolicies(globalNameId) {
		let response = await globalNamePolicies(globalNameId);

		return response;
	}

	/**
	 * Submits a confirmation note of user accepting a comprehensive agreement
	 * @param {{ first_name: any; middle_name: any; last_name: any; national_id: any; } | *} user
	 * @param {{ policy_prefix: any; policy_number: any; policy_id: any; } | *} policy
	 * @param {any} agreement
	 */
	static async sendComprehensiveAgreementNote(user, policy, agreement) {
		const timeRequest = await getServerTime();

		if (!timeRequest.success) throw new Error(timeRequest.message);
		const time = parseJSON(timeRequest.timestamp);

		const note = `On ${format(time, "MM/dd/yyyy")} at ${format(time, "h:mm a")}, ${user.first_name} ${
			user.middle_name
		} ${user.last_name} agreed to the following agreement: \n ${agreement} `;

		const payload = {
			link_id: policy.policy_id,
			description: "Comprehensive Agreed Value Addendum",
			notes: note,
			link_to: "policy",
			type: "Note For File",
			alert: false,
		};
		let response = await submitNote(payload);

		return response;
	}

	/**
	 * Submits a confirmation note of user accepting a Reduced Value agreement
	 * @param {any} branch
	 * @param {{ first_name: any; middle_name: any; last_name: any; national_id: any; }} user
	 * @param {{ policy_prefix: any; policy_number: any; policy_id: any; }} policy
	 * @param {any} agreement
	 */
	static async sendMaintainValueAgreementNote(branch, user, policy, agreement) {
		// const endpoint = "middleware/epic_mwSubmitNote";

		const time = Date.now();

		const note = `On ${format(time, "MM/dd/yyyy")} at ${format(time, "h:mm a")}, ${user.first_name} ${
			user.middle_name
		} ${user.last_name} agreed to the following agreement: \n ${agreement} `;

		// const headers = {
		// 	"x-auth-token":
		// 	"customer-id": `${branch} ${user.national_id} ${policy.policy_prefix}-${policy.policy_number}`,
		// 	"Content-Type": "application/json",
		// };

		const payload = {
			link_id: policy.policy_id,
			description: "Value Declaration Agreement",
			notes: note,
			link_to: "policy",
			type: "Note For File",
			alert: false,
		};

		// let response = await axios.post(endpoint, payload, {
		// 	headers: headers,
		// });
		let response = await submitNote(payload);

		return response;
	}

	/**
	 *
	 * @param {*} payload
	 * @returns
	 */
	static async globalNameSubmit(payload) {
		const endpoint = `/globalNameSubmit`;

		let response = {};
		response = checkKey({ endpoint, payload });

		return response;
	}

	/**
	 * Gets all risks associated with a specific policy
	 * @param {number} policy_id
	 */
	static async getPolicyRisks(policy_id, isRenewal = true) {
		let risks = await getPolicyRisks(policy_id, isRenewal);

		return risks;
	}

	/**
	 * Gets all transactions for the specified policy

	 * @param {String} policy_id
	 */
	static async getPolicyTransactions(policy_id) {
		let transactions = await getPolicyTransactions(policy_id);

		return transactions;
	}

	/**
	 * Gets list of street types

	 */
	static async getStreetTypes() {
		let streetTypes = await getStreetTypes();

		return streetTypes;
	}

	/**
	 * Gets list of parishes associated with each Town
	 -- the auth token for current session
	 * @param {String} param
	 */
	static async getParishTowns(param = "Jamaica") {
		let parishTowns = await getParishTowns(param);

		return parishTowns;
	}

	/**
	 * Gets list of all general areas
	 -- the auth token for current session
	 * @param {String} param
	 */
	static async getGeneralAreas(param = "Jamaica") {
		let generalAreas = await getGeneralAreas(param);

		return generalAreas;
	}

	/**
	 * Submits a confirmation note of user accepting a payment agreement
	 
	 * @param {Object} policy
	 * @param {Object} user
	 * @param {Object} premium
	 */
	static async sendPaymentAgreementNote(policy, user, premium) {
		// const endpoint = "middleware/epic_mwSubmitNote";

		// const headers = {
		// 	"x-auth-token":
		// 	"customer-id": `${branch} ${user.national_id} ${policy.policy_prefix}-${policy.policy_number}`,
		// 	"Content-Type": "application/json",
		// };

		const twoPartAgreement = `This Agreement is made between ${user.first_name} ${user.last_name} (hereinafter called the “Insured”) and Advantage General
			Insurance Company Limited (hereinafter called the “Company”) on the following binding terms and conditions:

			1. The premium payable for three (3) months’ cover shall be fifty percent (50%) of the Company’s decided annual premium
			or Ten Thousand Dollars ($10,000.00) whichever is greater, plus a non-refundable service charge of ($0) plus General
			Consumption Tax (the “Premium”). Upon payment of the Premium, the Company will issue a certificate of insurance
			(representing cover) which will expire no later than three (3) calendar months from the date of payment.

			2. The Company will offer insurance coverage for the period of nine (9) months immediately succeeding a three (3) months’
			cover upon receipt of a payment of the Premium and issue a certificate representing same, provided only:
			a. Payment must be receipted on or before the expiration of the three (3) months cover; and
			b. The Company MAY grant an adjusted coverage and issue a certificate of insurance which shall be effective only
			from the date of payment to the original annual policy period end date.

			3. If any cheque issued by the Insured to the Company is dishonoured or returned by the bank for any reason whatsoever,
			the policy of insurance shall be cancelled forthwith with effect from the date of inception of the period for which the
			cheque issued and the Insured shall be obliged by law to surrender the original certificate of insurance failing which he
			shall be liable on summary conviction to an offence.

			4. The Company shall not be liable for any incident giving rise to a claim during any period governed by this Agreement, in
			which the Premium has not been paid.

			5. There shall be no suspension of insurance coverage during the first three (3) month period of insurance governed by this
			Agreement.

			6. The Company will accommodate cancellation of the policy by the Insured during any period and the Insured shall be
			entitled to a refund of premium, less any time on cover charges for the period during which the policy has been in force,
			computed on the basis of the Company’s Short Period Rates provided that there have been no claims during the policy
			period.

			7. The Insured hereby declares that s/he (includes more than one insured) has read this Agreement and understands it or has
			requested that the Agreement be read to her/him and fully understands its contents and the implications arising
			therefrom.`;

		let nonTwoPartAgreement =
			"The following terms and conditions are supplementary to the Proposal. The insured hereby agrees to pay to\n";
		nonTwoPartAgreement =
			nonTwoPartAgreement +
			"Advantage General Insurance Company Limited the premiums stated on the policy or policies described herein,\n";
		nonTwoPartAgreement = nonTwoPartAgreement + "in accordance with the Payment Terms hereto and PROVIDED THAT:\n";
		nonTwoPartAgreement =
			nonTwoPartAgreement +
			"For value received, I hereby agree to pay the payments due as per the herein agreed Payment Terms, inclusive of all taxes, fees and any costs incurred, on the dates and in the amounts herein stated, subject otherwise to the terms, exclusions, provisions, and conditions of the Policy.\n";
		nonTwoPartAgreement =
			nonTwoPartAgreement +
			"In the event of any failure by me to make payments as per the Payment Terms herein agreed, Advantage General Insurance Company Limited is hereby authorized to cancel the Policy with immediate effect, without notice and without refund of any premiums paid.\n";
		nonTwoPartAgreement =
			nonTwoPartAgreement +
			"In the event of a claim being made during the currency of the policy, the total amount payable will immediately become due and payable in full.\n.";
		nonTwoPartAgreement =
			nonTwoPartAgreement +
			"Should any payment be dishonoured by the bank for any reason whatsoever, Advantage General Insurance Company Limited reserves the right to immediately terminate the policy in accordance with the conditions relating thereto.\n";

		const payload = {
			link_to: "policy",
			link_id: policy.policy_id,
			description: "Premium Payment Agreement",
			notes: premium.name.includes("2:50 Payment Plan") ? twoPartAgreement : nonTwoPartAgreement,
			type: "Note For File",
		};

		let response = await submitNote(payload);

		return response;
	}

	/**
	 *
	 * @param {Object} user
	 * @param {Object} policy
	 * @param {String} agreement
	 */
	static async sendValueDeclarationNote(user, policy, agreement) {
		const timeRequest = await getServerTime();

		if (!timeRequest.success) throw new Error(timeRequest.message);
		const time = parseJSON(timeRequest.timestamp);

		const note = `On ${format(time, "MM/dd/yyyy")} at ${format(time, "h:mm a")}, ${user.first_name} ${
			user.middle_name
		} ${user.last_name} agreed to the following agreement: \n ${agreement} `;

		// const headers = {
		// 	"x-auth-token":
		// 	"customer-id": `${branch} ${user.national_id} ${policy.policy_prefix}-${policy.policy_number}`,
		// 	"Content-Type": "application/json",
		// };

		const payload = {
			link_id: policy.policy_id,
			description: "Value Declaration Note",
			notes: note,
			link_to: "policy",
			type: "Note For File",
			alert: false,
		};

		return submitNote(payload);
		// return null;
	}

	/**
	 *
	 * @param {*} payload
	 */
	static async storeRenewalQuestions(payload) {
		let response = await submitNote(payload);

		return response;
	}

	/**
	 *
	 
	 * @param {*} uid
	 * @param {*} customer
	 * @param {*} email
	 * @param {*} questions
	 */
	static async sendQuestionsEmail(uid, customer, email, questions) {
		const endpoint = "email/questions";

		const headers = {
			"customer-id": uid,
			"Content-Type": "application/json",
		};

		const payload = {
			customer,
			questions,
			branchEmail: email,
		};

		return checkBadKey({ endpoint, payload, headers });
	}

	static async sendDirectEmail(uid, first_name, last_name, email, phone, trn) {
		const endpoint = "email/direct";

		const headers = {
			"customer-id": uid,
			"Content-Type": "application/json",
		};

		const payload = {
			first_name,
			last_name,
			email,
			phone,
			trn,
		};

		return checkBadKey({ endpoint, payload, headers });
	}

	/**
	 * Sends a registry email
	 
	 * @param {*} branch
	 * @param {*} policy
	 * @param {*} user
	 * @param {*} breakdown
	 * @param {String} notes
	 * @param {String} received_from
	 * @param {String} claim_id
	 * @param {number} policy_number
	 */
	static async sendRegistryEmail(
		branch,
		policy,
		user,
		received_from = "",
		breakdown,
		notes,
		claim_id,
		policy_number = 0,
		description = "Claim documents from Insured",
		registry_type = "To Report Claim",
		relating_to = "Claim",
		type_of_task = "To Report Claim",
		destination = "Claims",
		assigned_to_id = null
	) {
		if (relating_to === "Claim" && !isNotEmpty(assigned_to_id)) {
			const payload = {
				active_only: false,
				user_department: "Claims",
			};
			let response = await getClaimsHandler(payload);
			if (response.success) {
				const { users } = response;
				const user = users.find(u => u.user_name.includes("Online Claims Registry"));
				assigned_to_id = user.user_id;
			}
		}
		const payload = {
			associated_premium: breakdown.premium,
			branch: branch,
			claim_id,
			policy_number: `${policy.policy_number ? policy.policy_number : policy_number}`,
			client_global_name_id: user.global_name_id,
			date_due: format(new Date(), "MM/dd/yyyy"),
			date_received: format(new Date(), "MM/dd/yyyy"),
			destination,
			document_reference_number: !isNotEmpty(received_from)
				? user.first_name || user.last_name
					? `${user.last_name}${user.first_name?.charAt(0)}`
					: `${user.company_name}`
				: received_from,
			email: "",
			instructions: "",
			//`Premium + Service Charge + GCT = Total Premium↵${breakdown.premium} + 2000.00 + ${breakdown.GCT} = ${breakdown.totalDue}↵↵TRN: ${user.national_id}
			notes,
			policy_id: policy.policy_id,
			quotation_id: "",
			received_from: !isNotEmpty(received_from)
				? user.first_name || user.company_name
					? `${user.first_name} ${user.middle_name} ${user.last_name}`
					: `${user.company_name}`
				: received_from,
			registry_id: "",
			registry_type,
			relating_to,
			report_method: "WebSite",
			source_id: "",
			type_of_task,
			assigned_by: "Middleware",
			source_type: "Direct",
			registry_type_description: description,
			assigned_to_id,
		};

		let response = await registrySubmit(payload);

		return response;
	}

	/**
	 * Sends a registry email for cash payment
	 
	 * @param {*} branch
	 * @param {*} policy
	 * @param {*} user
	 * @param {*} breakdown
	 * @param {String} notes
	 * @param {String} received_from
	 * @param {number} claim_id
	 * @param {number} policy_number
	 * @param {String} instructions
	 */
	static async sendRegistryEmailForCashPayment(
		branch,
		policy,
		user,
		received_from = "",
		breakdown,
		notes,
		claim_id,
		policy_number = 0,
		description = "Claim documents from Insured",
		registry_type = "To Report Claim",
		relating_to = "Claim",
		type_of_task = "To Report Claim",
		destination = "Claims",
		instructions
	) {
		const payload = {
			assign_to_id: "",
			associated_premium: breakdown.premium,
			branch: branch,
			claim_id,
			policy_number: `${policy.policy_number ? policy.policy_number : policy_number}`,
			client_global_name_id: user.global_name_id,
			date_due: format(new Date(), "MM/dd/yyyy"),
			date_received: format(new Date(), "MM/dd/yyyy"),
			destination,
			document_reference_number: !isNotEmpty(received_from)
				? user.first_name || user.last_name
					? `${user.last_name}${user.first_name?.charAt(0)}`
					: `${user.company_name}`
				: received_from,
			email: "",
			instructions,
			//`Premium + Service Charge + GCT = Total Premium↵${breakdown.premium} + 2000.00 + ${breakdown.GCT} = ${breakdown.totalDue}↵↵TRN: ${user.national_id}
			notes,
			policy_id: policy.policy_id,
			quotation_id: "",
			received_from: !isNotEmpty(received_from)
				? user.first_name || user.last_name
					? `${user.first_name} ${user.middle_name} ${user.last_name}`
					: `${user.company_name}`
				: received_from,
			registry_id: "",
			registry_type,
			relating_to,
			report_method: "WebSite",
			source_id: "",
			type_of_task,
			assigned_by: "Middleware",
			source_type: "Direct",
			registry_type_description: description,
		};

		let response = await registrySubmit(payload);

		return response;
	}

	static sendCashRegistry(branch, policy, user, breakdown, instructions, id, description) {
		return this.sendRegistryEmailForCashPayment(
			branch,
			policy,
			user,
			"",
			breakdown,
			"",
			id,
			policy.policy_number,
			description,
			"Pay Premium",
			"Policy",
			"Pay Premium",
			"Cashier",
			instructions
		);
	}

	static async createRegistry(
		assign_to_id = "",
		associated_premium,
		branch,
		claim_id,
		client_global_name_id,
		destination,
		//`Premium + Service Charge + GCT = Total Premium↵${breakdown.premium} + 2000.00 + ${breakdown.GCT} = ${breakdown.totalDue}↵↵TRN: ${user.national_id}
		notes,
		registry_type = "To Report Claim",
		type_of_task = "To Report Claim",
		registry_type_description = "To report Claim",

		policy,
		user,
		breakdown
	) {}

	static async payBalance(
		uid,
		user,
		totalDue,
		branchEmail,
		cardNumber,
		cvv,
		expiry,
		cardType = "Visa",
		currency = "JMD",
		cardCountry = "JM"
	) {
		const endpoint = "transactions";

		const payload = {
			branchEmail,
			cardAmount: totalDue,
			cardCountry,
			cardExp: expiry,
			cardNumber,
			cardType,
			currency,
			cvv,
			cardName: `${user.last_name}, ${user.first_name} ${user.middle_name}`,
		};

		const headers = {
			"customer-id": uid,
		};

		return checkBadKey({ endpoint, payload, headers });
	}

	/**
	 
	 * @param {{ risks?: { manual_rates: any; }[]; policy_prefix?: any; policy_number?: any; limits: *[]; extensions: *[]; policy_id?: any; }} policy
	 * @param {string | any[]} risks
	 */
	static async premiumCalculator(policy, risks, planIds) {
		// const endpoint = "middleware/epic_mwRenewalPremiumCalculator";
		/** Headers */
		// const headers = {
		// 	"x-auth-token":
		// 	"customer-id": `${branch} ${user.national_id} ${policy.policy_prefix}-${policy.policy_number}`,
		// };

		/** Strip the fields from policy and risks */
		let nRisks = [];

		for (let i = 0; i < risks.length; i++) {
			nRisks.push({
				risk_id: risks[i].risk_id,
				sum_insured: risks[i].sum_insured,
				rates: risks[i].manual_rates,
				// takes the rate handling for calculation
				rate_handling: risks[i].rate_handling,
			});
		}

		let nlimits = policy.limits.map(limit => ({
			limit_code: limit.limit_code,
			limit_amount: limit.limit_amount,
		}));

		let nExtensions = policy.extensions.map(extension => ({
			extension_code: extension.extension_code,
			limit_amount: extension.limit_amount,
		}));
		let payload = {
			requestBody: {
				policy: {
					policy_id: policy.policy_id,
					payment_plan_ids: planIds,
					limits: nlimits,
					extensions: nExtensions,
					manual_extensions: nExtensions,
				},
				risks: nRisks,
			},
		};

		let response = await renewalPremiumCalculator(payload);

		return response;
	}

	/**
	 *
	 
	 * @param {*} policyPrefix
	 */
	static async getPolicyProfileExt(policyPrefix) {
		return getPolicyProfExt(policyPrefix);
	}

	/**
	 *
	 
	 * @param {*} policy_id
	 */
	static async getPolicyPaymentPlan(policy_id) {
		return getPolicyPaymentPlan(policy_id);
	}

	/**
	 *
	 
	 */
	static async getPaymentPlans(currency, policyPrefix) {
		let plans = await getPaymentPlans(currency, policyPrefix);

		return plans;
	}

	//policy mod
	static async batchPolicyModification({
		policy,
		startDate = "",
		endDate = "",
		trans_wording,
		risks = [],
		batch_actions = [],
		only_calculate_premium,
		transaction_premium,
	}) {
		let payload = {
			authorize_transaction: true,
			policy_id: policy.policy_id,
			effective_date: startDate,
			end_date: endDate,
			batch_actions: batch_actions,
			only_calculate_premium: only_calculate_premium,
			trans_wordings: {
				trans_wording: trans_wording,
			},
			transaction_premium: transaction_premium,

			is_tax_exempt: false,
		};

		console.log(payload);

		// relay to dotNet
		let response = genPolicyModification(payload);

		return response;
	}

	//policy mod
	static async generalPolicyMod({
		policy,
		startDate = "",
		endDate = "",
		trans_wording,
		risks = [],
		only_calculate_premium,
		transaction_premium,
	}) {
		let global_names = [...policy.global_names];
		let _policy = this.cleanPolicyForGeneralMod(policy);
		let _risks = this.cleanRisksForGeneralMod(risks);

		let nlimits = policy.limits.map(limit => ({
			limit_code: limit.limit_code,
			limit_amount: limit.limit_amount,
		}));

		let nExtensions = policy.extensions.map(extension => ({
			extension_code: extension.extension_code,
			limit_amount: extension.limit_amount,
		}));

		const motorPolicyPayload = () => {
			for (let i = 0; i < global_names.length; i += 1) {
				global_names[i] = { ...global_names[i] };
				if (global_names[i]) {
					delete global_names[i].poca;
					delete global_names[i].locations;
					delete global_names[i].email_address;
					delete global_names[i].phone_numbers;
				}
			}

			let payload = {
				authorize_transaction: true,
				policy_id: policy.policy_id,
				effective_date: startDate,
				end_date: endDate,
				payment_plan_id: policy.payment_plan,
				action: "general_modification",
				only_calculate_premium: only_calculate_premium,
				trans_wordings: {
					trans_wording: trans_wording,
				},
				transaction_premium: transaction_premium,
				parameters: {
					policy: {
						..._policy,
						country: _policy.country === "" ? "Jamaica" : _policy.country,
						manual_extensions: nExtensions,
					},
					global_names: global_names,
					risks: _risks,
				},

				is_tax_exempt: false,
			};

			return payload;
		};

		let payload;

		payload = motorPolicyPayload();

		// relay to dotNet
		let response = genPolicyModification(payload);

		return response;
	}

	static async renewAndUpdatePolicy(
		policy,
		paymentPlanId,
		codes,
		createCertificate,
		createCoverNote,
		effectiveCover,
		homePolicy,
		startDate = "",
		endDate = "",
		risks = [],
		user
	) {
		let global_names = [...policy.global_names];

		let _policy = this.cleanPolicyForRenewal(policy);
		let _risks = this.cleanRisksForRenewal(risks);

		const homePolicyPayload = () => {
			//let _policy = { ...policy };
			for (let i = 0; i < codes.length; i++) {
				for (let j = 0; j < _risks[i].manual_rates.length; j++) {
					if (codes[i].code === _risks[j].manual_rates[j].code) {
						_risks[j].manual_rates.splice(j, 1);
					}
				}
			}

			for (let i = 0; i < global_names.length; i += 1) {
				global_names[i] = { ...global_names[i] };
				if (global_names[i]) {
					delete global_names[i].poca;
					delete global_names[i].locations;
					delete global_names[i].email_address;
					delete global_names[i].phone_numbers;
				}
			}

			return {
				authorize_transaction: true,
				policy: {
					..._policy,
					source_name: "Direct", // hardcoded based on legacy
					end_date: endDate,
					start_date: format(new Date(startDate), "MM/dd/yyyy H:mm:ss"),
					insureds: _policy.insureds,
					payment_plan_id: paymentPlanId,
				},
				global_names: global_names,
				risks: _risks,
			};
		};

		//const data = homePolicyPayload(); // only homePolicy can be generated for re-engagement flow it seems by the legacy application

		const motorPolicyPayload = () => {
			//let _policy = { ...policy, global_names };
			for (let i = 0; i < global_names.length; i += 1) {
				global_names[i] = { ...global_names[i] };
				if (global_names[i]) {
					delete global_names[i].poca;
					delete global_names[i].locations;
					delete global_names[i].email_address;
					delete global_names[i].phone_numbers;
				}
			}

			// for (let i = 0; i < codes.length; i++) {
			// 	for (let j = 0; j < _risks[0].manual_rates.length; j++) {
			// 		if (codes[i].code === _risks[0].manual_rates[j].code) {
			// 			_risks[0].manual_rates.splice(j, 1);
			// 		}
			// 	}
			// }

			for (let i = 0; i < _risks.length; i += 1) {
				_risks[i] = { ..._risks[i] };
				if (risks[i].authorized_driver_wording) {
					delete _risks[i].authorized_driver_wording;
					_risks[i].sum_insured = _risks[i].renewal_sum_insured;
					_risks[i].year_for_rating = _risks[i].year;
				}
			}

			delete _policy.extensions;
			return {
				authorize_transaction: true,
				create_certificate: createCertificate,
				create_cover_note: createCoverNote,
				cover_note_details: {
					effective_date: startDate,
					days_effective: effectiveCover,
					manual_numbers: risks.map(risk => ({
						risk_id: risk.risk_id,
						risk_external_id: "",
						manual_number: 11111121559,
					})),
					/* {
							risk_id: risks[0].risk_id,
							risk_external_id: "",
							manual_number: 11111121559,
						}, */
				},
				// trans_wording: "",
				policy: {
					..._policy,
					// branch: config.receipt_branch_name,
					source_name: "Direct", // hardcoded based on legacy
					end_date: endDate,
					start_date: format(new Date(startDate), "MM/dd/yyyy H:mm:ss"),
					insureds: _policy.insureds,
					payment_plan_id: paymentPlanId,
					//limit_group: "",
				},
				global_names: global_names,
				risks: _risks,
			};
		};

		let payload;
		if (homePolicy) {
			payload = homePolicyPayload();
		} else {
			payload = motorPolicyPayload();
		}

		// relay to dotNet
		let response = updateRenewPolicy(payload);

		return response;
	}

	static cleanPolicyForRenewal(policy) {
		let _policy = JSON.parse(JSON.stringify(policy));
		/* let removeProp = (obj, field) =>  */

		delete _policy.account_code;
		delete _policy.account_name;
		delete _policy.billing_account_code;
		delete _policy.cancel_date;
		delete _policy.cancel_reason_details;
		delete _policy.date_for_renewal_to_start;
		delete _policy.effective_cancel_date;
		delete _policy.endorsements_that_apply;
		delete _policy.estimated_maximum_loss;
		delete _policy.fa_excess_percent;
		delete _policy.fa_excess_description;
		delete _policy.global_names;
		delete _policy.inception_date;
		delete _policy.insured;
		delete _policy.insured_text;
		delete _policy.is_cancelled;
		delete _policy.is_cancelled;
		delete _policy.is_in_renewal;
		delete _policy.is_loss_of_profit_policy;
		delete _policy["is_pre-existing_policy"];
		delete _policy.is_premium_financed;
		delete _policy.missing_documents;
		delete _policy.other_risk_details;
		delete _policy.peril_type;
		delete _policy.risks;
		delete _policy.sections_that_dont_apply;
		delete _policy.source_account_code;
		delete _policy.source_comm_rate;
		delete _policy.tax_percent;
		delete _policy.territorial_limits;
		delete _policy.type_of_cover;
		delete _policy.warranties_do_not_apply;

		return _policy;
	}

	static cleanRisksForRenewal(risks) {
		let _risks = [...risks];

		for (let a = 0; a < _risks.length; a++) {
			let _risk = { ..._risks[a] };

			let _address = { ..._risks[a].address };

			_address.country = "Jamaica";

			//TODO: look into drivers property

			delete _risk.certificates;
			delete _risk.cover_notes;
			delete _risk.loadings;
			delete _risk.mortgagee_text;
			delete _risk.risk_description;
			delete _risk.risk_section_number;
			delete _risk.veh_cert_expiry_date;
			delete _risk.veh_cnote_expiry_date;
			delete _risk.vehicle_location;

			_risks[a] = { ..._risk, address: _address };
		}

		return _risks;
	}

	static cleanPolicyForGeneralMod(policy) {
		let _policy = JSON.parse(JSON.stringify(policy));
		/* let removeProp = (obj, field) =>  */

		delete _policy.account_code;
		delete _policy.account_name;
		delete _policy.billing_account_code;
		delete _policy.cancel_date;
		delete _policy.cancel_reason_details;
		delete _policy.date_for_renewal_to_start;
		delete _policy.effective_cancel_date;
		delete _policy.endorsements_that_apply;
		delete _policy.estimated_maximum_loss;
		delete _policy.fa_excess_percent;
		delete _policy.fa_excess_description;
		delete _policy.global_names;
		delete _policy.inception_date;
		delete _policy.insured;
		delete _policy.insured_text;
		delete _policy.is_cancelled;
		delete _policy.is_cancelled;
		delete _policy.is_in_renewal;
		delete _policy.is_loss_of_profit_policy;
		delete _policy["is_pre-existing_policy"];
		delete _policy.is_premium_financed;
		delete _policy.missing_documents;
		delete _policy.other_risk_details;
		delete _policy.peril_type;
		delete _policy.risks;
		delete _policy.sections_that_dont_apply;
		delete _policy.source_account_code;
		delete _policy.source_comm_rate;
		delete _policy.tax_percent;
		delete _policy.territorial_limits;
		delete _policy.type_of_cover;
		delete _policy.warranties_do_not_apply;
		delete _policy.payment_plan_id;

		return _policy;
	}

	static cleanRisksForGeneralMod(risks) {
		let _risks = [...risks];

		for (let a = 0; a < _risks.length; a++) {
			let _risk = { ..._risks[a] };

			//TODO: look into drivers property

			delete _risk.certificates;
			delete _risk.cover_notes;
			delete _risk.mortgagee_text;
			delete _risk.risk_description;
			delete _risk.risk_section_number;
			delete _risk.veh_cert_expiry_date;
			delete _risk.veh_cnote_expiry_date;
			delete _risk.vehicle_location;
			delete _risk.manual_rates;

			_risks[a] = _risk;
		}

		return _risks;
	}
	/**
	 *
	 
	 * param {*} trn
	 * @param {*} transactionId
	 */
	static async getTransactionSchedule(transactionId) {
		return getTransactionSchedule(transactionId);
	}

	/**
	 *
	 
	 * @param {*} policyId
	 * @param {*} riskId
	 */
	static async getCertificate(policyId, riskId) {
		// Relay to dotNet connected function
		return getCertificate(policyId, riskId);
	}

	/**
	 *
	 
	 * @param {*} policyId
	 * @param {*} riskId
	 */
	static async createCertificate(policyId, riskId) {
		// Relay to dotNet connected function
		return createCertificate(policyId, riskId);
	}

	/**
	 *
	 
	 * @param {*} policyId
	 * @param {*} riskId
	 */
	static async getCoverNote(policyId, riskId) {
		// Relay to dotNet connected function
		return getCoverNote(policyId, riskId);
	}

	/**
	 
	 * @param {{ auto_authorize: boolean; branch: string; receipt_date: string; policy_id: any; amount: number; payment_type: string; currency: string; is_tax_exempt: boolean; }[]} receiptArray
	 */
	static async drawReceipt(receiptArray) {
		return drawReceipt(receiptArray);
	}

	/*
	 * @param {{last_name: string;first_name: string;}} user
	 
	 * @param {any} trn
	 * @param {{ renewal_narratives: any; policy_number: any; }} policy
	 * @param {{paymentOption: any;email: any;}} transaction
	 * @param {any} renewalDetails3Month
	 * @param {any} files
	 * @param {string | any[]} ALLOWED_CERT_NAR_CODES
	 * @param {string | number | Date} _startDate
	 */
	/* 
	----- UNUSED FUNCTION 
	static async sendRenewalEmail(
		
		trn,
		policy,
		transaction,
		renewalDetails3Month,
		_startDate,
		files,
		ALLOWED_CERT_NAR_CODES
	) {
		const endpoint = "/middleware/renewalWithDocumentsAndOutstanding";

		const headers = {
			"x-auth-token": 
			"customer-id": trn,
			"content-type": "application/json",
		};

		let half = false;
		let balance = "";
		let payment = "";
		let extension = "";
		let startDate = "";
		let threemonth = renewalDetails3Month;
		const paymentOption = transaction.paymentOption;

		if (paymentOption === "Half Payment") {
			half = true;
			payment = threemonth.total;
			balance = threemonth.second_premium_payment;
			startDate = format(new Date(_startDate), "dd/MM/yyyy");

			if (
				isBefore(new Date(), new Date(threemonth.renewal_premium)) ||
				isEqual(new Date(), new Date(threemonth.renewal_premium))
			)
				extension = format(subDays(add(new Date(threemonth.effective_date), { months: 3 }), 1), "dd/MM/yyyy");
			else extension = format(subDays(add(new Date(), { months: 3 }), 1), "dd/MM/yyyy");
		}

		
		const payload = (summary, files, outstanding, email, half, payment, balance, startDate, extension) => {
			let out_docs = [];

			let isCertificateSendable = !(
				outstanding.filter(e => {
					return ALLOWED_CERT_NAR_CODES.includes(e.code);
				}).length >= 1
			);

			for (let i = 0; i < outstanding.length; i++) {
				out_docs[i] = outstanding[i].description;
			}

			return {
				files: files,
				outstandingDocs: out_docs,
				summary: {
					name: policy.last_name + ", " + policy.first_name,
					policyNumber: "" + summary.policy_number + "",
					canSend: isCertificateSendable,
				},

				email: email,

				half: half || false,
				payment: payment || "",
				balance: balance || "",
				startDate: startDate,
				extension: extension || "",
			};
		};

		const data = payload(
			policy,
			files,
			policy.renewal_narratives || [],
			transaction.email,
			half,
			payment,
			balance,
			startDate,
			extension
		);

		return checkBadKey({ endpoint, payload: data, headers });
	} */

	/**
	 *
	 
	 * @param {*} trn
	 * @param {*} policy
	 * @param {*} email
	 * @param {*} files
	 */
	static async sendExtendEmail(trn, policy, email, files) {
		const endpoint = "/email/extend";

		const headers = {
			"customer-id": trn,
			"content-type": "application/json",
		};

		let payload = {
			files,
			email,
			summary: {
				name: policy.account_name,
				policyNumber: "" + policy.policy_number + "",
			},
		};

		return checkBadKey({ endpoint, payload, headers });
	}

	/**
	 
	 * @param {string} policyId
	 * @param {Object} obj
	 *  */
	static async modifyPolicy(policyId, obj) {
		const endpoint = `/policyModification`;

		let payload = {
			policy_id: policyId,
			authorize_transaction: true,
			effective_date: obj.effective_date,
			action: "add_extensions",
			trans_wordings: obj.trans_wording,
			parameters: obj.parameter,
			is_tax_exempt: obj.is_tax_exempt,
		};

		return checkKey({ endpoint, payload });
	}
	/**
	 *
	 
	 * @param {*} policy
	 * @param {*} effectiveTime
	 * @param {*} premium
	 */
	static async extendPolicy(policy, effectiveTime, premium) {
		const endpoint = `/policyModification`;

		let shouldCreateCertificate = true;

		if (policy.department_class.toLowerCase() === "fire") shouldCreateCertificate = false;

		let payload = {
			policy_id: policy.policy_id,
			authorize_transaction: true,
			action: "create_next_payment_term_transaction",
			effective_time: effectiveTime,
			is_tax_exempt: false,
			create_certificate: shouldCreateCertificate,
			is_do_not_prorate: false,
			transaction_premium: premium,
		};

		let response = {};

		response = await checkKey({ endpoint, payload });

		return response;
	}

	static async persistStorage(
		policy,
		codes,
		extensions,
		branch,
		user,
		transaction,
		renewal_details,
		receipt,
		transaction_type,
		payment_plan
	) {
		const endpoint = "session/persist";

		const headers = {
			"customer-id": `${branch} ${user.national_id} ${policy.policy_prefix}-${policy.policy_number}`,
			"content-type": "application/json",
		};

		let payload = {};

		let session = {};

		session.user = JSON.stringify(user);
		session.currentTransaction = JSON.stringify(transaction);

		session.pendingDocuments = JSON.stringify(policy.missing_documents);
		session.codes = JSON.stringify(codes);
		session.policyDetails = JSON.stringify({ policy, risks: policy.risks });
		//session.extArry = JSON.stringify(this.extArry);
		session.globalNameId = JSON.stringify(user.globalNameId);
		session.risks = JSON.stringify(policy.risks);
		session.receipt = JSON.stringify(receipt);
		//save current policy as new var and check for it in getPolicy
		//save it by calling getPolicy with the policy id from ppolicy service
		session.policy = JSON.stringify(policy);
		session.menuOption = JSON.stringify(transaction_type);

		//session.date = JSON.stringify(=new Date())

		let policyInfo = {};

		//in policy service, persist current pol and extensions here
		policyInfo.currentPolicy = JSON.stringify(policy);
		policyInfo.extensions = JSON.stringify(extensions);

		let payments = {};
		payments.currentTransaction = JSON.stringify(transaction);

		payments.renewalDetails = JSON.stringify(renewal_details);
		/* payments.renewalDetails3Month = JSON.stringify(paymentService.renewalDetails3month)
		payments.renewalDetails9Month = JSON.stringify(paymentService.getRenewalDetails9month()); */
		payments.paymentPlan = JSON.stringify(payment_plan);

		let tracking = {};
		tracking.trackedEvents = JSON.stringify([]);

		payload.session = session;
		payload.policy = policyInfo;
		payload.payments = payments;
		payload.tracking = tracking;
		payload.trn = user.national_id;

		//let response = await axios.post(endpoint, payload, { headers: headers }); //new PersistSessionRequest(state, this.http,  this.tracking).execute();
		return checkBadKey({ endpoint, payload, headers });
	}

	/**
	 *
	 
	 * @param {*} from
	 * @param {*} to
	 * @param {*} effective_date
	 * @param {*} country
	 */
	static async getExchangeRate(from, to, effective_date, country) {
		// relay to dotNet
		return getExchangeRate(from, to, effective_date, country);
	}

	/**
	 *
	 * @param {Object} prop
	 
	 * @param {*} prop.user
	 * @param {*} prop.policy
	 * @param {*} prop.paymentForm
	 * @param {*} prop.session_type
	 * @param {*} prop.receipt_method
	 * @param {Boolean=} prop.was_broker_policy
	 * @param {*} prop.branch
	 * @param {*=} prop.cart
	 * @param {*} prop.source
	 * @returns
	 */
	static async saveSession(
		{ user, policy, paymentForm, session_type, receipt_method, was_broker_policy, branch, cart, source }

	) {
		const endpoint = "session/savex";

		let payment_plan_id = paymentForm?.paymentType?.payment_plan_id;
		let { global_name_id, national_id, dob, gender, email_address: receive_email_at } = user;

		let headers = {
			"customer-id": `${national_id}`,
		};

		const payload = {
			policy_id: policy.policy_id,
			global_name_id,
			session_type,
			receipt_method,
			receive_email_at,
			payment_plan_id,
			national_id,
			dob,
			gender,
			total_payments: paymentForm.totalPayments || -1,
			session: JSON.stringify({
				user,
				policy,
				paymentForm,
				session_type,
				was_broker_policy /*  cart */,
				branch,
				source
			}),
		};

		let response = {};
		try {
			response = await checkExpToken({ endpoint, payload, headers });
			// if must be logged in, get new token and retry once
			if (!response.success && response.message.includes("Must be logged in")) {
				const token = await AuthNetworkLayer.login();
				headers = {
					"customer-id": `${national_id}`,
				};
				response = await checkExpToken({ endpoint, payload, headers });
			}
		} catch (error) {
			if (error.message.includes("Must be logged in")) {
				const token = await AuthNetworkLayer.login();
				headers = {
					"customer-id": `${national_id}`,
				};
				response = await checkExpToken({ endpoint, payload, headers });
			}
		}

		return response;
	}

	/**
	 *
	 
	 * @param {*} policyPrefix
	 */
	static async getPolicyRatingCodes(policyPrefix) {
		return getPolicyProfRatingCodes(policyPrefix);
	}

	/**
	 * Remove missing documents (narratives) from a policy
	 *
	 *
	 */
	static async removePolicyNarrative(policy_id, narratives) {
		return await updatePolicyNarratives({ policy_id, narratives_to_remove: narratives });
	}

	/**
	 * Add missing documents (narratives) to a policy
	 */
	static async addPolicyNarrative(policy_id, narratives) {
		return await updatePolicyNarratives({ policy_id, narratives_to_add: narratives });
	}

	/**
	 *
	 
	 * @param {*} policy_id
	 * @param {*} policy_start_date
	 * @param {*} policy_end_date
	 * @param {*} risk_id
	 * @param {*} registration_number
	 * @param {*} is_do_not_prorate
	 * @returns
	 */
	static async notingRegistrationNumber(
		policy_id,
		policy_start_date,
		policy_end_date,
		risk_id,
		registration_number,
		is_do_not_prorate
	) {
		return notingRegistrationNumber({
			policy_id,
			policy_start_date,
			policy_end_date,
			risk_id,
			registration_number,
			is_do_not_prorate,
		});
	}

	static async setToCancel(data) {
		return setToCancel(data);
	}

	static async cancelPolicy(data) {
		return cancelPolicy(data);
	}

	static async updateReceiptTransaction(sessionUpdatePayload) {
		try {
			const activePolicy = sessionUpdatePayload.fields_to_update.activePolicy;
			delete sessionUpdatePayload.fields_to_update.activePolicy;

			const sessionUpdateEndpoint = "/session/update";
			const sessionUpdateHeaders = {
				Accept: "application/json;*/*",
				"Content-Type": "application/json",
			};

			const sessionUpdateResponse = await checkExpToken({
				endpoint: sessionUpdateEndpoint,
				payload: sessionUpdatePayload,
				headers: sessionUpdateHeaders,
				timeout: 20000,
				reqType: "put",
			});

			const payload = {
				policy_number: activePolicy.policy_number,
				reminder_type: "cash",
			};

			await removePaymentReminder(payload);

			if (!sessionUpdateResponse || !sessionUpdateResponse?.success) {
				console.log("The session failed to be updated in the database.");
				//throw new Error("The receipt number failed to write to the database.");
			}
		} catch (e) {
			console.log(e?.message);
		}

		return sessionUpdatePayload;
	}

	static async savePaymentDetails(savePaymentDetailsPayload) {
		//let frontendRequestStart = Date.now();
		const endpoint = "session/save-payment-methods";

		const payload = { fields_to_add: savePaymentDetailsPayload };

		let response = await checkExpToken({ endpoint, payload, reqType: "put" });

		/* setTimeout(() => {
			if (response.data?.backendTiming) {
				logPerformanceTiming({
					frontendRequestStart,
					serverTiming: response.data?.backendTiming,
					call_name: "save_payment_details",
				});
			}
		}); */

		return response;
	}

	static async getSessionByReceiptNumber(receipt_no) {
		const endpoint = `session/getSessionByReceiptNumber`;

		let payload = {
			receipt_no: receipt_no,
		};

		return await checkExpToken({ endpoint, payload, reqType: "post" });
	}

	static async savePaymentActivity({ saveSessionPayload, savePaymentDetailsPayload, sessionUpdatePayload, sessionTracking }) {
		let saveSessionResponse;
		let savePaymentDetailsResponse;
		let updateSessionResponse;

		const { sessionData, setSelectedSession, dispatch } = sessionTracking;

		try {

			if (sessionData.sessionId && sessionData.isSessionCreated) {

				sessionUpdatePayload.session_id = sessionData.sessionId;

				updateSessionResponse = this.updateReceiptTransaction(sessionUpdatePayload);
			}
			else {
				saveSessionResponse = await this.saveSession(saveSessionPayload);

				const savedSessionId = saveSessionResponse.session_id;

				dispatch(setSelectedSession({sessionId: savedSessionId, isSessionCreated: true}));

				savePaymentDetailsPayload.session_id = savedSessionId;

				sessionUpdatePayload.session_id = savedSessionId;

				updateSessionResponse = this.updateReceiptTransaction(sessionUpdatePayload);
				let paymentsList = [
					{
						...savePaymentDetailsPayload,
					},
				];

				paymentsList = paymentsList.map(p => Object.values(p));

				savePaymentDetailsResponse = await this.savePaymentDetails(paymentsList);
			}

		} catch (e) {
			throw new Error("Error: ", e);
		} finally {
			return {
				saveSessionResponse: saveSessionResponse,
				savePaymentDetailsResponse: savePaymentDetailsResponse,
				updateSessionResponse: updateSessionResponse,
			};
		}
	}
}
