import React, { useState, useEffect, useRef } from "react";
import { setToken, setUserId, setUserFormData, setCurrentUser, getTransactionType } from "globals/auth";
import { setLoading, setNotification } from "globals/overlays";
import { sub, format, getDaysInMonth, parse } from "date-fns";
import { clearConfirmationData } from "globals/confirmation";
import { useDispatch, batch, useSelector } from "react-redux";
import calendar_icon from "assets/img/claims_icons/calendar.svg";

// eslint-disable-next-line
import { Row, Col } from "reactstrap";
import Select, { Styles } from "react-select";
import { Formik, Form, Field } from "formik";
import { AuthNetworkLayer } from "helpers";
import { useHistory } from "react-router";
import MaskedInput from "react-text-mask";
import { enUS } from "date-fns/locale";
import { object, string, boolean } from "yup";
import Modal from "components/misc/Modal";
import jwtDecoder from "jwt-decode";
import "./Auth.scss";
// import { getNewGlobalToken } from "helpers/auth";
import { PAY_BALANCE } from "pages/RenewPolicy/costants";
import { ErrorModal } from "components/misc/SweetModal";
import { ERROR } from "components/misc/Modal/constants";
import { createUseStyles } from "react-jss";
import Calendar from "components/CustomDatePicker/Calandar/Calendar";
import InfoIcon from "components/misc/InfoIcon";
import { createLog } from "helpers/log";
import { fullLogout } from "globals/misc";

const redirect = function() {
	window.open("https://quote-and-buy-v3-fe.azurewebsites.net/quote-and-buy/get-started", "_blank")
}

// import CustomDatePicker from "../CustomDatePicker";
const useStyles = createUseStyles({
	tabButtons: {
		"& button": {
			width: "195px",
			minHeight: "30px",
			maxHeight: "30px",
			borderRadius: "0",
			border: "none",
			backgroundColor: "#F0F0F0",
			color: "var(--primary-color) !important",
			fontSize: "14px",
			fontWeight: "bold",
			padding: "10px 0",
			lineHeight: "12px",
			borderTopLeftRadius: "15px",
			borderTopRightRadius: "15px",
			"&:first-child": {},
			"&:last-child": {
				border: "2px solid lightgray",
				borderBottom: "none",
				borderLeft: "none"
			},
			"&:hover": {
				cursor: "pointer",
			},
		},
	},
	active: {
		backgroundColor: "var(--action-btn-color-2) !important",
		color: "var(--primary-color) !important",
	},

	//Responsiveness for mobile
	"@media (max-width: 767px)": {
		tabButtons: {
			"& button": {
				width: "49%",
			},
		},
	},
});
/** @type {import("react-select").Styles} */
const customStylesDate = {
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#002868" : state.isFocused ? "#adcdff" : "#f0f3f6",
		color: state.isSelected ? "#fff" : "#212529",
		fontWeight: "normal",
		padding: "0.5rem 1.3rem",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const style = {
			borderStyle: "none",
			fontWeight: 400,
		};

		return { ...style, opacity, transition };
	},

	dropdownIndicator: (provided, state) => ({
		display: "none",
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		visibility: "hidden",
	}),
	control: provided => ({
		...provided,
		color: "#212529",
		height: 33.3,
		// padding: "0 2rem",
		// paddingLeft: "0.5em",
		borderRadius: "10px",
		border: "1px solid #002868",
	}),
	placeholder: (provided, state) => ({
		fontWeight: "bold",
		opacity: 0.5,
		// marginLeft: -9,
	}),
	menuList: (provided, state) => ({
		height: "250px",
		overflowY: "auto",
		paddingBottom: "4px",
		paddingTop: "4px",
		position: "relative",
		boxSizing: "border-box",
		minWidth: "100px",
	}),
};
const customStyles = {
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#002868" : state.isFocused ? "#adcdff" : "#fff",
		color: state.isSelected ? "#f0f3f6" : "#212529",
		fontWeight: "normal",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const style = {
			borderStyle: "none",
			fontWeight: 800,
			fontSize: 13,
		};

		return { ...style, opacity, transition };
	},

	control: (provided, state) => ({
		...provided,
		color: "#212529",
		height: 35,
		// padding: "0.5rem 0.5rem",
		"@media (max-width: 767px)": {
			padding: 0,
			fontSize: 12,
		},
		borderRadius: 23,
		border: "2px solid #BCBCBC",
		backgroundColor: "white",
		"@media (max-width: 768px)": {
			padding: "0",
			// paddingTop: "0.3rem",
			fontSize: 14,
		},
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		opacity: 1,
		fontSize: 12,
		display: "none",
		"@media (max-width: 767px)": {
			display: "none",
		},
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		visibility: "hidden",
	}),

	placeholder: (provided, state) => ({
		fontWeight: "bold",
		opacity: 0.5,
	}),
	menuList: (provided, state) => ({
		height: "100px",
		overflowY: "auto",
		paddingBottom: "4px",
		paddingTop: "4px",
		position: "relative",
		boxSizing: "border-box",
	}),
};
const customStylesNew = {
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#002868" : state.isFocused ? "#adcdff" : "#fff",
		color: state.isSelected ? "#f0f3f6" : "#212529",
		fontWeight: "normal",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const style = {
			borderStyle: "none",
			fontWeight: 800,
			fontSize: 13,
		};

		return { ...style, opacity, transition };
	},

	control: (provided, state) => ({
		...provided,
		color: "#212529",
		height: 35,
		// padding: "0.5rem 0.5rem",
		"@media (max-width: 767px)": {
			padding: 0,
			fontSize: 12,
		},
		borderRadius: 0,
		border: "2px solid #BCBCBC",
		backgroundColor: "white",
		"@media (max-width: 768px)": {
			padding: "0",
			// paddingTop: "0.3rem",
			fontSize: 14,
		},
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		opacity: 1,
		fontSize: 12,
		display: "none",
		"@media (max-width: 767px)": {
			display: "none",
		},
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		visibility: "hidden",
	}),

	placeholder: (provided, state) => ({
		fontWeight: "bold",
		opacity: 0.5,
	}),
	menuList: (provided, state) => ({
		height: "100px",
		overflowY: "auto",
		paddingBottom: "4px",
		paddingTop: "4px",
		position: "relative",
		boxSizing: "border-box",
	}),
};
const customStylesLeft = {
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#002868" : state.isFocused ? "#adcdff" : "#fff",
		color: state.isSelected ? "#f0f3f6" : "#212529",
		fontWeight: "normal",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const style = {
			borderStyle: "none",
			fontWeight: 800,
			fontSize: 13,
		};

		return { ...style, opacity, transition };
	},

	control: (provided, state) => ({
		...provided,
		color: "#212529",
		height: 35,
		// padding: "0.5rem 0.5rem",
		"@media (max-width: 767px)": {
			padding: 0,
			fontSize: 12,
		},
		borderRadius: "23px 0 0 23px",
		border: "2px solid #BCBCBC",
		backgroundColor: "white",
		"@media (max-width: 768px)": {
			padding: "0",
			// paddingTop: "0.3rem",
			fontSize: 14,
		},
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		opacity: 1,
		fontSize: 12,
		display: "none",
		"@media (max-width: 767px)": {
			display: "none",
		},
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		visibility: "hidden",
	}),

	placeholder: (provided, state) => ({
		fontWeight: "bold",
		opacity: 0.5,
	}),
	menuList: (provided, state) => ({
		height: "100px",
		overflowY: "auto",
		paddingBottom: "4px",
		paddingTop: "4px",
		position: "relative",
		boxSizing: "border-box",
	}),
};
const customStylesRight = {
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#002868" : state.isFocused ? "#adcdff" : "#fff",
		color: state.isSelected ? "#f0f3f6" : "#212529",
		fontWeight: "normal",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const style = {
			borderStyle: "none",
			fontWeight: 800,
			fontSize: 13,
		};

		return { ...style, opacity, transition };
	},

	control: (provided, state) => ({
		...provided,
		color: "#212529",
		height: 35,
		// padding: "0.5rem 0.5rem",
		"@media (max-width: 767px)": {
			padding: 0,
			fontSize: 12,
		},
		borderRadius: "0 23px 23px 0",
		border: "2px solid #BCBCBC",
		backgroundColor: "white",
		"@media (max-width: 768px)": {
			padding: "0",
			// paddingTop: "0.3rem",
			fontSize: 14,
		},
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		opacity: 1,
		fontSize: 12,
		display: "none",
		"@media (max-width: 767px)": {
			display: "none",
		},
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		visibility: "hidden",
	}),

	placeholder: (provided, state) => ({
		fontWeight: "bold",
		opacity: 0.5,
	}),
	menuList: (provided, state) => ({
		height: "100px",
		overflowY: "auto",
		paddingBottom: "4px",
		paddingTop: "4px",
		position: "relative",
		boxSizing: "border-box",
	}),
};

//Generating a list of years for year selection
const now = new Date();
const thisYear = now.getFullYear();
const old = sub(now, { years: 100 });
const oldYear = old.getFullYear();
const years = Array(thisYear - oldYear)
	.fill("")
	.map((_, i) => {
		return { value: thisYear - i, label: thisYear - i };
	});
const monthArray = [];
for (let i = 0; i < 12; i++) {
	monthArray.push(enUS.localize.month(i));
}
const months = monthArray.map((month, i) => {
	return { value: i + 1, label: month.slice(0, 3).toUpperCase() };
});
const dayDefault = Array(31)
	.fill("")
	.map((_, i) => {
		return { value: (i + 1).toString(), label: (i + 1).toString() };
	});

const genders = [
	{ value: "Male", label: "Male" },
	{ value: "Female", label: "Female" },
];

const defaultRegisterValues = {
	dobDay: "",
	dobMonth: "",
	dobYear: "",
	trn: "",
	companyTRN: "",
	gender: "",
	companyName: "",
	isIndividual: true,
};

const authFormSchema = object().shape({
	dobDay: string().when("isIndividual", {
		is: true,
		//then: string().required("Please choose a valid day"),
		then: string().required("Invalid day"),
	}),
	dobMonth: string().when("isIndividual", {
		is: true,
		//then: string().required("Please choose a valid month"),
		then: string().required("Invalid month"),
	}),
	dobYear: string().when("isIndividual", {
		is: true,
		//then: string().required("Please choose a valid year"),
		then: string().required("Invalid year"),
	}),
	trn: string().when("isIndividual", {
		is: true,
		then: string()
			.required("Please enter a valid TRN")
			.matches(/^[\d]{3}-[\d]{3}-[\d]{3}$/, "Please enter a valid TRN"),
	}),
	companyTRN: string().when("isIndividual", {
		is: false,
		then: string()
			.required("Please enter a valid TRN")
			.matches(/^[\d]{3}-[\d]{3}-[\d]{3}$/, "Please enter a valid TRN"),
	}),

	gender: string().when("isIndividual", {
		is: true,
		then: string().required("Please choose a gender"),
	}),
	isIndividual: boolean(),
	companyName: string().when("isIndividual", {
		is: false,
		then: string().required("Company Name is required"),
		otherwise: string(),
	}),
});

const TRNMask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];

/** Persist the Auth that lives across refresh */
export default function Auth({
	match,
	isClaims = false,
	isTrackAClaim = false,
	isPayBalance = false,
	isPolicyManager = false,
	generatingNewToken,
}) {
	const dispatch = useDispatch();
	const formikRef = useRef();
	const history = useHistory();
	const classes = useStyles();
	const transactionType = useSelector(getTransactionType);
	const [tab, setTab] = useState(match.url.split("/").pop());
	const [isIndividual, setIsIndividual] = useState(true);
	const [open, openModal] = useState(false);
	const [days, setDays] = useState(dayDefault);
	const [remoteServerError, setRemoteServerError] = useState(false);

	//dispatch(fullLogout());
	const closeModal = () => {
		openModal(false);
	};
	const currentTime = new Date();
	useEffect(() => {
		setTab(match.url.split("/").pop());
	}, [match.url]);
	let dob;
	const handleFormSubmit = async formValues => {
		dispatch(setLoading(true));
		setIsIndividual(formValues.isIndividual);
		if (formValues.isIndividual) {
			dob = format(
				parse(`${formValues.dobYear} ${formValues.dobMonth} ${formValues.dobDay}`, "yyyy MM dd", new Date()),
				"yyyy-MM-dd"
			);
		}

		/** Pull data from form to be sent for authentication */
		const userData = {
			dob: dob,
			is_a_company: !formValues.isIndividual,
			national_id: formValues.isIndividual ? formValues.trn.replace(/-/g, "") : formValues.companyTRN.replace(/-/g, ""),
			national_id_type: "TRN",
			gender: formValues.gender,
			company_name: formValues.isIndividual ? "" : formValues.companyName.trim(),
		};

		dispatch(setUserFormData(userData));

		try {
			/** Remove any leftover data */
			dispatch(clearConfirmationData());
			/** Try to acquire token for user */
			generatingNewToken.current = true;
			// const loginRequest = await getNewGlobalToken(3);
			// if (!loginRequest.token) throw new Error("Failed to acquire token");

			/** Try to acquire global name id for future requests */
			const globalNameIdRequest = await AuthNetworkLayer.getGlobalNameId(userData);

			if (globalNameIdRequest.IsMethodCallSuccessful === false) {
				setRemoteServerError(true);
			} else if (!globalNameIdRequest.success) {
				setRemoteServerError(false);
				throw new Error("Failed to get global name ID");
			}

			/** Try to acquire encoded user data for future requests */
			const otpMethodsResponse = await AuthNetworkLayer.getUserData(				
				userData,
				globalNameIdRequest.global_name_id
			);
			/**
			 * Verify token signature
			 * @type {*}
			 */
			const otpMethods = jwtDecoder(otpMethodsResponse.result);
			if (!otpMethods.success) throw new Error("Failed to acquire options for OTP verification");

			batch(() => {
				dispatch(setToken(otpMethodsResponse.result));
				dispatch(setUserId(globalNameIdRequest.global_name_id));
				dispatch(
					setCurrentUser({
						phone_numbers: otpMethods.phone_numbers,
						email_address: otpMethods.email_address,
						first_name: otpMethods.first_name,
						last_name: otpMethods?.last_name,
						mother_maiden_name: otpMethods?.mother_maiden_name,
					})
				);
				dispatch(setLoading(false));
			});

			generatingNewToken.current = false;

			if (isClaims) {
				history.replace(`/claims/otp`);
			} else if (transactionType === PAY_BALANCE) {
				history.replace(`/${PAY_BALANCE}/otp`);
			} else if (isTrackAClaim) {
				history.replace(`/track-my-claim/otp`);
			} else if (isPayBalance) {
				history.push("/paybalance/otp");
			} else if (isPolicyManager) {
				history.push("/update-your-policy/otp");
			} else {
				history.replace(`/renewal/otp`);
			}
		} catch (e) {
			if (e.message === "Unable to connect to the remote server" || e.message.match(/error/i)) {
				setRemoteServerError(true);
			}
			openModal(true);
		} finally {
			dispatch(setLoading(false));
			generatingNewToken.current = false;
			createLog({
				trn: formValues.isIndividual ? formValues.trn.replace(/-/g, "") : formValues.companyTRN.replace(/-/g, ""),
				type: `${transactionType}-Login`,
				data: { ...formValues },
				errors: {
					message: open
						? remoteServerError
							? "Server error has occurred"
							: "Could not find a record in our database"
						: "",
				},
			});
		}
	};

	/**
	 * Checks whether the submit button should be disabled
	 * @param {defaultRegisterValues} values
	 */
	function shouldDisableSubmit(values, errors) {
		//If isIndividual do not check for company name and trn
		if (values.isIndividual) {
			return (
				Object.entries(errors).length !== 0 ||
				!values.dobMonth ||
				!values.dobDay ||
				!values.dobYear ||
				!values.trn ||
				!values.gender
			);
		} else {
			return !values.companyName || !values.companyTRN || Object.entries(errors).length !== 0;
		}
	}

	/**
	 * Custom handler for DOB fields
	 * @param {*} e
	 * @param {*} f
	 * @param {*} values
	 * @param {*} setFieldValue
	 */
	function handleDOBChange(e, f, values, setFieldValue) {
		let daysInMonth = 31;

		if ((f.name === "dobMonth" && values.dobYear.length > 0) || (f.name === "dobYear" && values.dobMonth.length > 0)) {
			daysInMonth = getDaysInMonth(
				parse(
					`${f.name === "dobYear" ? e.value : values.dobYear} ${f.name === "dobMonth" ? e.value : values.dobMonth}`,
					"yyyy MM",
					new Date()
				)
			);
			setDays(
				Array.from(Array(daysInMonth), (_, i) => {
					return { value: (i + 1).toString(), label: (i + 1).toString() };
				})
			);
		}

		//Reset day (when other values changed) or (when days in month is less than selection)
		if (f.name !== "dobDay" && parseInt(values.dobDay) > daysInMonth) setFieldValue("dobDay", "");

		setFieldValue(f.name, e.value.toString());
	}

	const updateDay = newDay => {
		// if (newDay !== undefined && newDay != "") setDay(newDay);
	};

	const updateMonth = newMonth => {
		// if (newMonth !== undefined && newMonth != "") setMonth(newMonth);
	};

	const updateYear = newYear => {
		// if (newYear !== undefined && newYear != "") setYear(newYear);
	};
	const daysInMonth = (month, year) => {
		if (month === currentTime.getMonth() + 1 && year === currentTime.getFullYear()) {
			return currentTime.getDate();
		}
		return new Date(year, month, 0).getDate();
	};

	const getSelectedDate = (selectedDate, setFieldValue, values) => {
		if (selectedDate !== undefined) selectedDate = new Date(selectedDate);
		let day = selectedDate.getDate() + 1;
		let month = selectedDate.getMonth() + 1;
		let year = selectedDate.getFullYear();
		let daysAmount = daysInMonth(month, year);

		if (day === 32) {
			day = 1;

			if (month === 12) {
				month = 1;
				year++;
			} else {
				month++;
			}
		} else if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
			day = 1;
			month++;
		} else if (month === 2) {
			var isLeap = new Date(parseInt(year), 1, 29).getMonth() === 1;

			if (!isLeap && day === 29) {
				day = 1;
				month++;
			}
		} else {
			day = day;
		}

		handleDOBChange({ value: day }, { name: "dobDay" }, values, setFieldValue);
		handleDOBChange({ value: month }, { name: "dobMonth" }, values, setFieldValue);
		handleDOBChange({ value: year }, { name: "dobYear" }, values, setFieldValue);

		const returnDaysAmountArray = (daysAmount = 31) => {
			return Array(daysAmount)
				.fill("")
				.map((_, i) => {
					return {
						value: i < 9 ? 0 + "" + (i + 1).toString() : (i + 1).toString(),
						label: i < 9 ? 0 + "" + (i + 1).toString() : (i + 1).toString(),
					};
				});
		};

		let daysArray = returnDaysAmountArray(daysAmount);
		setDays(daysArray);
	};

	return (
		<div className="auth-container constrain-small">
			{isPolicyManager ? (
				<>
					<h1 className="content-title" style={{color: "#797979"}} > Welcome!</h1>
					{/* <div className="instruction">Here you can edit details related to your insurance policy. </div> */}
				</>
			) : (
				<>
					<h1 className="content-title" style={{color: "#797979"}} > Welcome!</h1>
	
				</>
			)}
			<Modal
				modalType={ERROR}
				open={open}
				title="Unable to Login"
				content={
					remoteServerError ? (
						<div>It’s not you, It’s us. Please retry later or contact us at (888) OUR-AGIC (687-2442).</div>
					) : (
						<div>
							{isIndividual
								? "Oops! Something's not right. We could not find a record in our database. Please check each field to ensure that the information is correct."
								: "Oops! Something's not right. We could not find a record in our database. Please ensure that the company’s name matches your insurance document"}
						</div>
					)
				}
				primaryLabel="Ok"
				primaryCallback={closeModal}
			/>

			<Formik
				innerRef={formikRef}
				initialValues={defaultRegisterValues}
				validationSchema={authFormSchema}
				onSubmit={handleFormSubmit}
			>
				{({
					isSubmitting,
					errors,
					values,
					touched,
					setFieldValue,
					setFieldTouched,
					handleBlur,
					validateField,
					resetForm,
				}) => (
					<Form className="auth-content" style={{marginTop: "2px"}}>
						{/* Tab buttons to toggle between Individual and Company */}

						{tab === "login" && (
							<>
								<div style={{ marginBottom: "0px", gap:"2%", display: "flex" }} className={classes.tabButtons}>
									<button
									style={{}}
										type="button"
										className={values.isIndividual ? classes.active : ""}
										onClick={() => {
											// resetForm();
											setFieldValue("isIndividual", true);
										}}
									>
										Individual
									</button>
									<button
										type="button"
										className={!values.isIndividual ? classes.active : ""}
										onClick={() => {
											// resetForm();
											setFieldValue("isIndividual", false);
										}}
									>
										Corporate
									</button>
								</div>
								<Row
									className="form signup p-md-3 p-1 m-0"
									style={{
										border: "2px solid #c4d600",
										borderBottom: "4px solid #c4d600",
										background: "rgba(255,255,255,0.9)",
										boxShadow: "5px 5px 4px lightgray",
										minHeight: "403px"
									}}
								>
									{values.isIndividual ? (
										<>
											<Col lg={12} md={12} sm={12} xs={12}>
												<label
													className={
														"form-label-login" +
														`${values.trn ? " hasValue" : ""}` +
														`${touched.trn && errors.trn ? " hasError" : ""}`
													}
												>
													<div>
														<span className="field-title login-title">{"TRN "}</span>
														<span className="required">*</span>
													</div>

													<Field
														as={MaskedInput}
														type="text"
														name="trn"
														className="fieldNew"
														guide={false}
														mask={TRNMask}
														maxLength={11}
														onBlur={handleBlur}
													/>
													{touched.trn && errors.trn && <div className="error">{errors.trn}</div>}
												</label>
											</Col>

											<Col lg={{ size: 12 }}>
												<Row className="dob-wrapper mb-md-0 mt-3">
													<Col lg={12} style={{position: "relative"}}>
														<div
															className={
																"fieldset-labelNew mb-3" +
																`${values.dobMonth || values.dobYear || values.dobDay ? " hasValue" : ""}` +
																`${ touched.dobYear && errors.dobYear || touched.dobDay && errors.dobDay || touched.dobMonth && errors.dobMonth ? " hasError" : ""}`
															}
														>
															<span className="field-title login-title">{"Date of Birth "}</span>
															<span className="required">*</span>
														</div>
														
													</Col>

													{/* <div className="fieldset-row"> */}
													<Col lg={3} md={3} sm={4} xs={4}
														style={{paddingRight: "0px"}}
													>
														<label className="dob-field">
															<Field name="dobMonth" className="fieldNew">
																{({ field }) => (
																	<Select
																		{...field}
																		value={
																			values.dobMonth
																				? { value: values.dobMonth, label: months[values.dobMonth - 1].label }
																				: null
																		}
																		placeholder="mm"
																		options={months}
																		blurInputOnSelect
																		styles={customStylesLeft}
																		classNamePrefix="react-select"
																		className={
																			"react-select-container" +
																			`${values.dobMonth ? " hasValue" : ""}` +
																			`${touched.dobMonth && errors.dobMonth ? " hasError" : ""}`
																		}
																		onBlur={() => {
																			setFieldTouched("dobMonth", true, false);
																			validateField("dobMonth");
																		}}
																		onChange={(e, f) => handleDOBChange(e, f, values, setFieldValue)}
																		
																	/>
																)}
															</Field>
															{/* {touched.dobMonth && errors.dobMonth && <div className="error">{errors.dobMonth}</div>} */}
														</label>
													</Col>
													<Col lg={3} md={3} sm={4} xs={4}
													
														style={{paddingRight: "0px"}}
													>
														{" "}
														<label className="dob-field">
															<Field name="dobDay" className="fieldNew">
																{({ field }) => (
																	<Select
																		{...field}
																		options={days}
																		value={values.dobDay ? { value: values.dobDay, label: values.dobDay } : null}
																		placeholder="dd"
																		blurInputOnSelect
																		styles={customStylesNew}
																		classNamePrefix="react-select"
																		className={
																			"react-select-container" +
																			`${values.dobDay ? " hasValue" : ""}` +
																			`${touched.dobDay && errors.dobDay ? " hasError" : ""}`
																		}
																		onBlur={() => {
																			setFieldTouched("dobDay", true, false);
																			validateField("dobDay");
																		}}
																		noOptionsMessage={() => "Please choose a year and month first"}
																		onChange={(e, f) => handleDOBChange(e, f, values, setFieldValue)}
																	/>
																)}
															</Field>
															{/* {touched.dobDay && errors.dobDay && <div className="error">{errors.dobDay}</div>} */}
														</label>
													</Col>
													<Col lg={3} md={3} sm={4} xs={4}
														style={{paddingRight: "0px"}}
													>
														<label className="dob-field">
															<Field name="dobYear" className="fieldNew">
																{({ field }) => (
																	<Select
																		{...field}
																		value={values.dobYear ? { value: values.dobYear, label: values.dobYear } : null}
																		options={years}
																		blurInputOnSelect
																		placeholder="yyyy"
																		styles={customStylesRight}
																		classNamePrefix="react-select"
																		className={
																			"react-select-container" +
																			`${values.dobYear ? " hasValue" : ""}` +
																			`${touched.dobYear && errors.dobYear ? " hasError" : ""}`
																		}
																		onBlur={() => {
																			setFieldTouched("dobYear", true, false);
																			validateField("dobYear");
																		}}
																		onChange={(e, f) => handleDOBChange(e, f, values, setFieldValue)}
																	/>
																)}
															</Field>
															{/* {touched.dobYear && errors.dobYear && <div className="error">{errors.dobYear}</div>} */}
														</label>
													</Col>
													<Col
														lg={3}
														md={3}
														sm={3}
														xs={3}
														className={
															"calendar-responsiveness " +
															`${Object.keys(errors).length !== 0 ? "" : "my-auto mx-auto"}`
														}
													>
														<Calendar
															getSelectedDate={selectedDate => getSelectedDate(selectedDate, setFieldValue, values)}
														/>
													</Col>
												</Row>
												{(touched.dobYear && errors.dobYear || touched.dobDay && errors.dobDay || touched.dobMonth && errors.dobMonth)? <div className="" style={{fontSize: "12px", color: "red", lineHeight: "12px", marginTop:"8px", fontWeight: "bold"}}>Please enter a valid Date</div>: null}
											</Col>

											<Col lg={12} md={12} sm={12} xs={12}>
												{" "}
												<label
													className={
														"form-label-login" +
														`${values.gender ? " hasValue" : ""}` +
														`${touched.gender && errors.gender ? " hasError" : ""}`
													}
													style={{
														marginTop: "15px"
													}}
												>
													<div>
														<span className="field-title login-title">{"Gender "}</span>
														<span className="required">*</span>
													</div>
													<Field name="gender" className="fieldNew">
														{({ field }) => (
															<Select
																{...field}
																value={values.gender ? { value: values.gender, label: values.gender } : null}
																options={genders}
																blurInputOnSelect
																isSearchable={true}
																styles={customStyles}
																classNamePrefix="react-select"
																className={
																	"field react-select-container" +
																	`${values.gender ? " hasValue" : ""}` +
																	`${touched.gender && errors.gender ? " hasError" : ""}`
																}
																onBlur={() => {
																	setFieldTouched("gender", true, false);
																	validateField("gender");
																}}
																onChange={e =>
																	// @ts-ignore
																	setFieldValue(field.name, e.value)
																}
															/>
														)}
													</Field>
													{touched.gender && errors.gender && <div className="error">{errors.gender}</div>}
												</label>
											</Col>

										</>
									) : (
										<>
											{/* Two Columns one for Company Name and One for TRN */}
											<Col lg={12} md={12} sm={12} xs={12}>
												<label
													className={
														"form-label-login" +
														`${values.companyName ? " hasValue" : ""}` +
														`${touched.companyName && errors.companyName ? " hasError" : ""}`
													}
												>
													<div>
														<span className="field-title login-title">{"Company Name "}</span>
														<span className="required">*</span>
														<InfoIcon label="Enter the company’s name as presented on your insurance document." />
													</div>

													<Field name="companyName" type="text" className="fieldNew" onBlur={handleBlur} />
													{touched.companyName && errors.companyName && (
														<div className="error">{errors.companyName}</div>
													)}
												</label>
											</Col>
											<Col lg={12} md={12} sm={12} xs={12}>
												<label
													className={
														"form-label-login" +
														`${values.companyTRN ? " hasValue" : ""}` +
														`${touched.companyTRN && errors.companyTRN ? " hasError" : ""}`
													}
												>
													<div>
														<span className="field-title login-title">{"TRN "}</span>
														<span className="required">*</span>
													</div>

													<Field
														as={MaskedInput}
														type="text"
														name="companyTRN"
														className="fieldNew"
														guide={false}
														mask={TRNMask}
														maxLength={11}
														onBlur={handleBlur}
													/>
													{touched.companyTRN && errors.companyTRN && <div className="error">{errors.companyTRN}</div>}
												</label>
											</Col>
										</>
									)}
									<Col lg={12}>
										<div className="text-center"
											style={{margin: "10px"}}
										>
											<button
												className="button primary-theme-btn"
												disabled={shouldDisableSubmit(values, errors) || isSubmitting}
												type="submit"
											>
												Get Started
											</button>
										</div>
									</Col>
									<Col lg={12}>
										<div className="text-center">
											
											<div
												style={{fontSize: "12px"}}
											>Want to purchase a new policy?</div>
											<div
												style={{
													color:"var(--primary-color)",
													textDecoration: "underline",
													fontWeight: "bold",
													fontSize: "12px"
											
												}}

												onClick={()=> {redirect()}}
											>
												CLICK HERE
											
											</div>
										</div>
									</Col>
								</Row>
							</>
						)}
					</Form>
				)}
			</Formik>
		</div>
	);
}
